import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faUsers, faEnvelope, faCartShopping } from "@fortawesome/free-solid-svg-icons";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

import { API_URL } from "../../../config";

ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const AdminStatsBox = ({ icon, title, value, graphData }) => {
    return (
        <div className="admin-dashboard-stats-box">
            <div className="admin-dashboard-stats-value-content">
                <div className="admin-dashboard-stats-value-icon"><FontAwesomeIcon icon={icon} /></div>
                <div className="admin-dashboard-stats-title">
                    <div className="admin-dashboard-stats-title-text">{title}</div>
                    <div className="admin-dashboard-stats-value-text">{value}</div>
                </div>
            </div>
            <div className="admin-dashboard-stats-graph-container">
                <Line
                    options={{
                        spanGaps: 1000 * 60 * 60 * 24,
                        responsive: true,
                        interaction: {
                            mode: 'nearest',
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                type: 'time',
                                display: true,
                                time: {
                                    unit: "day"
                                }
                            },
                            y: {
                                display: false
                            }
                        }
                    }}

                    data={{
                        datasets: [
                            {
                                fill: true,
                                borderColor: "#1880f8",
                                backgroundColor: "#1f7aef",
                                data: graphData.map(g => { return { x: new Date(g[0]), y: g[1] }}),
                                spanGaps: 1000 * 60 * 60 * 24,
                            }
                        ]
                    }}
                />
            </div>
        </div>
    );
};

const AdminSmallStatsBox = ({ icon, title, value }) => {
    return (
        <div className="admin-dashboard-small-stats-box">
            <div className="admin-dashboard-stats-value-icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="admin-dashboard-stats-title">
                <div className="admin-dashboard-stats-title-text">{title}</div>
                <div className="admin-dashboard-stats-value-text">{value}</div>
            </div>
        </div>
    );
};

const RecentOrdersTable = ({ orders }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order, index) => (
                    <tr>
                        <td>{order.title}</td>
                        <td>${order.total_order_value.toFixed(2)}</td>
                        <td><div className={`userorders-table-status userorders-table-status-${order.status.toLowerCase()}`}>{order.status.replaceAll("_", " ")}</div></td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/orders/${order.order_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const Index = () => {
    const [last24h, setLast24h] = useState(null);
    const [lastWeek, setLastWeek] = useState(null);
    const [lastMonth, setLastMonth] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [viewContent, setViewContent] = useState(null);

    const [recentOrders, setRecentOrders] = useState([]);
    const [ticketsAwaitingResponse, setTicketsAwaitingResponse] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const request = await fetch(`${API_URL}/admin/dashboard/stats`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await request.json();
                if(!data.success) {
                    if(request.status === 403) return navigate("/auth/login");
                    setError(data.response);
                    setLoaded(false);
                    return;
                };
                setLast24h(data.last_24hours);
                setLastWeek(data.last_week);
                setLastMonth(data.last_month);
                setSelectedView("month");
                setRecentOrders(data.recent_orders);
                setTicketsAwaitingResponse(data.tickets_awaiting_staff);
                setTotalUsers(data.total_users);
                setTotalOrders(data.total_orders);
                setLoaded(true);
            } catch(e) {
                console.log(e);
                setError("An unknown error occurred, please try again later");
                setLoaded(true);
            };
        };
        fetchData();
    }, []);

    const renderSelectedView = () => {
        if(selectedView === "24h") {
            setViewContent(<>
                <AdminStatsBox icon={faDollarSign} title="Revenue" value={last24h.revenue_total} graphData={last24h.revenue_data} />
                <AdminStatsBox icon={faDollarSign} title="Orders Created" value={last24h.orders_total} graphData={last24h.orders_data} />
                <AdminStatsBox icon={faUsers} title="New Users" value={last24h.users_total} graphData={last24h.users_data} />
            </>)
        } else if(selectedView === "week") {
            setViewContent(<>
                <AdminStatsBox icon={faDollarSign} title="Revenue" value={lastWeek.revenue_total} graphData={lastWeek.revenue_data} />
                <AdminStatsBox icon={faCartShopping} title="Orders Created" value={lastWeek.orders_total} graphData={lastWeek.orders_data} />
                <AdminStatsBox icon={faUsers} title="New Users" value={lastWeek.users_total} graphData={lastWeek.users_data} />
            </>)
        } else if(selectedView === "month") {
            setViewContent(<>
                <AdminStatsBox icon={faDollarSign} title="Revenue" value={lastMonth.revenue_total} graphData={lastMonth.revenue_data} />
                <AdminStatsBox icon={faCartShopping} title="Orders Created" value={lastMonth.orders_total} graphData={lastMonth.orders_data} />
                <AdminStatsBox icon={faUsers} title="New Users" value={lastMonth.users_total} graphData={lastMonth.users_data} />
            </>)
        };
    };

    useEffect(() => {
        renderSelectedView();
    }, [selectedView]);
    
    if(error !== false) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="admin-dashboard-index">
            <div className="admin-dashboard-select-view">
                <button className={`btn btn-select-view ${selectedView === "24h" ? "btn-select-view-active": ""}`} onClick={() => setSelectedView("24h")}>1D</button>
                <button className={`btn btn-select-view ${selectedView === "week" ? "btn-select-view-active": ""}`} onClick={() => setSelectedView("week")}>1WK</button>
                <button className={`btn btn-select-view ${selectedView === "month" ? "btn-select-view-active": ""}`} onClick={() => setSelectedView("month")}>1M</button>
            </div>
            <div className="admin-dashboard-index-stats">
                {viewContent}
            </div>
            <div className="admin-dashboard-index-content">
                <div className="admin-dashboard-index-orders">
                    <div className="item-title-margin">
                        <div className="user-home-welcome-title">Recent Orders</div>
                        <div className="user-home-welcome-text">The last 5 most recent orders</div>
                    </div>
                    <RecentOrdersTable orders={recentOrders} />
                </div>
                <div className="admin-dashboard-stats-right">
                    <AdminSmallStatsBox icon={faEnvelope} title="Tickets Awaiting Staff" value={ticketsAwaitingResponse} />
                    <AdminSmallStatsBox icon={faUsers} title="Total Users" value={totalUsers} />
                    <AdminSmallStatsBox icon={faCartShopping} title="Total Orders" value={totalOrders} />
                </div>
            </div>
        </div>
    );
};

export default Index;